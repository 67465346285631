/**
 * @file standalone/product-brief.scss
 *
 * \brief Library file - shared styles for product brief
 *
 */

@import '../theme-bootstrap';

.product {
  &-flag {
    color: $color-gray;
    font-weight: 700;
    text-transform: uppercase;
  }
  &-points {
    color: $color-gray;
  }
  &-hair-quiz-match {
    color: $color-gray;
  }
  &__image-link {
    // Values based on the IMAGE_MEDIUM size: 330x548.
    flex-basis: 100% * (548/330);
    aspect-ratio: 330/548;
  }
  &__inventory-status {
    font-size: 12px;
    color: $color-gray-darker;
  }
  &__inventory-status-link {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.product-grid {
  &__item {
    .product-brief {
      &:hover {
        .product-brief__header {
          .product__image--alt {
            opacity: 1;
          }
        }
      }
      &__header {
        .product__image--alt {
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity 0.3s;
        }
      }
    }
  }
}

.product-brief {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 10px 32px;
    position: relative;
  }
  &__header {
    display: flex;
    position: relative;
  }
  .sold-out {
    display: none !important;
  }
  &__flag-text-link,
  &__rating-link {
    width: 100%;
  }
  &__flag-text-link {
    padding-top: 1em;
    height: 100%;
  }
  &__rating-link {
    padding-bottom: 3px;
    height: 100%;
  }
  &__footer {
    line-height: 1.25em;
    text-align: $ldirection;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    .temp-out-of-stock {
      @include breakpoint($portrait-up) {
        position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
        text-align: center;
      }
    }
    a,
    a:hover {
      text-decoration: none;
      display: inline-block;
    }
    a.button {
      &.product__button--add-to-bag {
        position: absolute;
        bottom: 0;
      }
    }
    &.coresite {
      padding-bottom: 48px;
      .product-brief__subline {
        overflow: auto;
        max-height: 150px;
        a.button {
          &.product__button--add-to-bag {
            bottom: 77px;
            min-width: 0;
            width: calc(100% - 20px);
            line-height: get-line-height(15px, 16px);
            height: unset;
            padding: 12px;
          }
        }
      }
    }
    .artist_content__pbutton {
      margin-top: auto;
    }
  }
  &__gap-footer {
    padding-bottom: 1em;
  }
  &__gap {
    padding-bottom: 10px;
  }
  &__add-to-bag {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    .button {
      padding: 12px;
      width: 100%;
      &.product__button--add-to-bag {
        width: 100%;
      }
      &.button--disabled {
        cursor: not-allowed;
        &:hover,
        &:active {
          color: $white;
        }
      }
    }
    .search-wrapper & {
      .button {
        width: 100%;
        &.product__button--add-to-bag {
          width: 100%;
        }
      }
    }
    .quiz-product-card & {
      display: none;
    }
  }
  &__sku-select {
    width: 100%;
    &:not(:empty) {
      height: 35px;
    }
    @include breakpoint($portrait-up) {
      &:not(:empty) {
        height: 30px;
        .search-wrapper & {
          height: 35px;
        }
      }
    }
    .sku-menu__container {
      margin: 0;
      .select-box {
        @include breakpoint($portrait-up) {
          height: 30px;
        }
      }
      .sku-menu__single-link {
        text-decoration: none;
        &:hover {
          color: $color-off-black;
        }
      }
      .sku-menu__single {
        font-size: 14px;
      }
    }
  }
  &__shades {
    margin-top: auto;
    width: 100%;
  }
  &__misc-flag {
    color: darken($color-gray, 20%);
    min-height: 22px;
  }
  &__name {
    font-weight: 500;
    text-transform: lowercase;
    a {
      text-decoration: none;
      &:hover {
        color: $color-off-black;
      }
    }
  }
  &__subline {
    &.default-height {
      height: 100%;
      a {
        height: 100%;
      }
    }
    a:hover {
      color: $color-off-black;
    }
  }
  &__name,
  &__rating,
  &__subline,
  &__price {
    line-height: get-line-height(17px, 26px);
  }
  &__sku-shade-swatch {
    display: inline-block;
    margin-#{$rdirection}: 0.5em;
    padding: 0.75em;
  }
  // hover state fields
  &__extras {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    @include breakpoint($landscape-up) {
      z-index: 1;
      display: none;
    }
    &-link {
      display: block;
      min-height: 150px;
      position: absolute;
      width: 100%;
      z-index: 1;
      top: 24px;
      height: 95%;
    }
  }
  @include breakpoint($landscape-up) {
    &:hover .product-brief__extras {
      display: block;
    }
  }
  &__badge {
    .product-badge {
      position: absolute;
      top: 10px;
      #{$rdirection}: 0;
      width: auto;
      text-align: #{$rdirection};
      @include breakpoint($landscape-up) {
        margin-top: 10px;
        top: 5px;
      }
      &__icon {
        margin-top: 10px;
      }
      &__image {
        width: 65%;
        @include breakpoint($landscape-up) {
          width: 75%;
        }
      }
      &--align-left {
        #{$rdirection}: auto;
        #{$ldirection}: -20px;
        @include breakpoint($landscape-up) {
          #{$ldirection}: -5px;
        }
      }
    }
  }
  &__rating {
    display: block;
    height: 100%;
    .product-rating__stars--off {
      position: relative;
    }
  }
  &__favorites {
    display: none;
    position: absolute;
    #{$rdirection}: 0;
    top: 3px;
    @include breakpoint($portrait-up) {
      display: block;
    }
  }
  .product-flag__flag {
    color: $color-gray;
    font-weight: 700;
    text-transform: uppercase;
  }
  .product-rating__count {
    display: none;
  }
  .sku-menu-chip {
    display: none;
  }
  .product-points,
  .product-full__installment_price {
    display: none;
  }
  .product-points-link {
    text-decoration: none;
  }
  &__price-search {
    display: none;
  }
}

.product-brief.focused .product-brief__extras {
  display: block;
}
